@media only screen and (min-width: 1281px) and (max-width: 1550px) {
  .page .en_US .main .intro-warp div p {
    width: 690px;
    margin: 15px auto 0 auto;
  }

  .page .main .intro-warp .intro-img {
    width: 380px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .page {
    .layout {
      .header {
        display: none;
      }

      .header_2 {
        display: block;
        z-index: 999;
      }

      .main {
        .banner_warp {
          margin-top: 70px;
          height: 42vw;
        }

        .intro-warp {
          height: auto;
          margin: 72px 0;

          &>div {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            div {
              padding-top: 60px;
            }
          }

          div {
            img {
              width: 310px;
            }

            h3 {
              margin-top: 20px;
              font-size: 28px;
            }

            h4 {
              font-size: 16px;
            }

            p {
              margin-top: 16px;
              font-size: 18px;
            }

            .slash {
              margin-top: 15px;
              text-align: center;

              img {
                width: 40px;
              }
            }
          }
        }

        .content_warp {
          .sub-nav {
            ul {
              min-width: 860px;
            }
          }

          .content {
            .games {
              ul {
                li {
                  a {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 20px;

                    &>div {
                      width: 552px;
                    }
                  }
                }
              }
            }

            .notice_list,
            .new_list {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
            }
          }
        }

      }

      .footer {
        .center-content {
          width: calc(100% - 40px);
        }
      }
    }

    .en_US {
      .main .content_warp .content .games ul li a {
        h3 {
          margin-bottom: 0;
        }

        div {
          width: 750px;

          p {
            line-height: 18px;
          }
        }
      }
    }
  }

  .secondary_page {
    .layout {
      .main {
        .banner_warp {
          margin-top: 0px;
          height: 480px;

          .secondary_page_title {
            padding: 70px 0 0 0;
          }
        }
      }
    }
  }
}

// ipad pro
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .page {
    .layout {
      .header {
        display: none;
      }

      .header_2 {
        display: block;
        z-index: 999;
      }

      .main {
        .banner_warp {
          margin-top: 70px;
          height: 42vw;
        }

        .intro-warp {
          height: 420px;
          margin: 64px 0;

          &>div {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;

            div {
              padding-top: 50px;
            }
          }

          div {
            img {
              width: 280px;
            }

            h3 {
              margin-top: 14px;
              font-size: 24px;
            }

            h4 {
              font-size: 14px;
            }

            p {
              margin-top: 10px;
              font-size: 16px;
            }

            .slash {
              display: none;
              margin-top: 8px;
              padding: 0;
              text-align: center;

              img {
                width: 35px;
              }
            }

            .game_icons {
              // display: none;
            }
          }
        }

        .content_warp {
          .sub-nav {
            width: 100%;

            ul {
              width: 100%;

              li:last-child {
                margin-right: 10px;
              }
            }
          }

          .content {
            .games {
              ul {
                li {
                  a {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 10px;

                    &>div {
                      width: 512px;
                    }

                    p {
                      margin-bottom: 0;
                    }

                    .game-6-logo {
                      width: 162px;
                      margin-left: 16px;
                    }

                    .game-8-logo,
                    .game-9-logo {
                      width: 180px;
                    }

                    .game-11-logo {
                      height: 100px;
                    }

                    .game-10-logo {
                      width: 100px;
                      margin-left: 40px;
                    }
                  }
                }
              }
            }

            .notice_list,
            .new_list {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
            }

            .manage_team {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
              margin: 70px 0 0 0;
            }

            .about_us {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 70px 0 0 0;

              p {
                margin-top: 35px;
              }
            }

            .company {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 70px 0 0 0;
            }

            .new_detail {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
            }

            .investor_info {
              width: 94%;
              min-height: calc(100vh - 520px - 480px - 140px);

              .investor_info_content {
                &>div {
                  width: 49%;
                  padding: 26px 10px 0 45px;

                  p {
                    font-size: 15px;

                    img {
                      left: -24px;
                      transform: scale(0.9);
                    }

                    .mail_img {
                      left: -32px;
                    }
                  }

                  .postCode {
                    img {
                      left: -28px;
                    }
                  }

                }
              }
            }
          }
        }

      }

      .footer {
        padding: 30px 0 0 0;
        height: 300px;

        .center-content {
          width: calc(100% - 40px);

          .content-img {
            text-align: center;
          }

          .content-word {
            margin-top: 10px;
            flex-direction: column;
            align-items: center;

            .statements {
              width: 170px;
              margin-bottom: 10px;

              dl {
                dt {
                  margin-bottom: 8px;
                }
              }
            }

            .info-list {
              display: none;
            }

            .info-list-mobile {
              display: block;

              p {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .en_US {
      .main .content_warp .content .games ul li {

        // @widthRation: 0.7;
        // &.game-bg-1 a img { width: 192px * @widthRation; }
        // &.game-bg-2 a img { width: 189px * @widthRation; }
        // &.game-bg-3 a img { width: 181px * @widthRation; }
        // &.game-bg-4 a img { width: 183px * @widthRation; }
        // &.game-bg-5 a img { width: 231px * @widthRation; }
        // &.game-bg-6 a img { width: 211px * @widthRation; }
        // &.game-bg-7 a img { width: 214px * @widthRation; }
        // &.game-bg-8 a img { width: 197px * @widthRation; }
        a {
          div {
            width: 585px;

            h3 {
              font-size: 14px;
              margin-bottom: 5px;
            }

            .l_game_1_title {
              margin-bottom: 0;
              line-height: 14px;
            }

            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }

    }
  }

  .secondary_page {
    .layout {
      .main {
        .banner_warp {
          margin-top: 0px;
          height: 480px;

          .secondary_page_title {
            padding: 70px 0 0 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 850px) {
  .page {
    .en_US {
      .main .content_warp .content .games ul li {
        @widthRation: 0.7;

        &.game-bg-1 a img {
          width: 192px * @widthRation;
        }

        &.game-bg-2 a img {
          width: 189px * @widthRation;
        }

        &.game-bg-3 a img {
          width: 181px * @widthRation;
        }

        &.game-bg-4 a img {
          width: 183px * @widthRation;
        }

        &.game-bg-5 a img {
          width: 231px * @widthRation;
        }

        &.game-bg-6 a img {
          width: 211px * @widthRation;
          max-width: 132px;
        }

        &.game-bg-7 a img {
          width: 214px * @widthRation;
          max-width: 132px;
        }

        &.game-bg-8 a img {
          width: 197px * @widthRation;
        }

        &.game-bg-9 a img {
          width: 198px * @widthRation;
        }

        &.game-bg-10 a img {
          width: 141px * @widthRation;
          margin-left: 16px;
        }

        &.game-bg-11 a img {
          width: 164px * @widthRation;
          margin-left: 10px;
        }
      }
    }
  }
}

// ipad
@media only screen and (min-width: 676px) and (max-width: 768px) {
  .page {
    .layout {
      .header {
        display: none;
      }

      .header_2 {
        display: block;
        z-index: 999;
      }

      .main {
        .banner_warp {
          margin-top: 70px;
          height: 42vw;
        }

        .intro-warp {
          height: 420px;
          margin: 64px 0;

          &>div {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;

            div {
              padding-top: 55px;
            }
          }

          div {
            img {
              width: 260px;
            }

            h3 {
              margin-top: 16px;
              font-size: 24px;
            }

            h4 {
              font-size: 14px;
            }

            p {
              margin-top: 20px;
              font-size: 16px;
            }

            .slash {
              display: none;
              padding: 0;
              margin-top: 8px;
              text-align: center;

              img {
                width: 25px;
              }
            }

            .game_icons {
              padding-top: 60px;
              // display: none;
            }
          }
        }

        .content_warp {
          .sub-nav {
            ul {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              right: 0;
              justify-content: center;
            }
          }

          .content {
            padding-bottom: 30px;

            .games {
              display: none;

              ul {
                li {
                  a {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 20px;

                    img {
                      width: 140px;
                      margin-right: 20px;
                    }

                    .game-5-logo {
                      left: 0;
                    }

                    .game-6-logo {
                      left: 0;
                    }

                    &>div {
                      width: 512px;
                    }

                    p {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }

            .game-mobile {
              display: block;
            }

            .notice_list,
            .new_list {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
            }

            .manage_team {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
              margin: 30px 0 0 0;

              ul {
                li {
                  flex-direction: column;
                  align-items: center;

                  &>div {
                    margin: 20px 0 0 0;

                    h4 {
                      text-align: left;
                      display: block;
                    }
                  }
                }
              }
            }

            .about_us {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 30px 0 0 0;

              img {
                width: 136px;
              }

              p {
                margin-top: 15px;
              }
            }

            .company {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 30px 0 0 0;
            }

            .new_detail {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
            }

            .investor_info {
              width: 100%;
              padding: 0 20px;
              min-height: calc(100vh - 520px - 480px - 140px);

              h3 {
                font-size: 22px;
                line-height: 28px;
              }

              h4 {
                margin-top: 0px;
                font-size: 14px;
                line-height: 20px;
              }

              .investor_info_content {
                flex-direction: column;
                margin-top: 24px;

                &>div {
                  width: 100%;
                  height: 120px;
                  padding: 26px 10px 0 60px;

                  p:last-child {
                    margin-top: 10px;
                  }

                  &:last-child {
                    margin-top: 24px;
                  }
                }
              }
            }
          }
        }

      }

      .footer {
        padding: 30px 0 0 0;
        height: 300px;

        .center-content {
          width: calc(100% - 40px);

          .content-img {
            text-align: center;
          }

          .content-word {
            margin-top: 10px;
            flex-direction: column;
            align-items: center;

            .statements {
              width: 170px;
              margin-bottom: 10px;

              dl {
                dt {
                  margin-bottom: 8px;
                }
              }
            }

            .info-list {
              display: none;
            }

            .info-list-mobile {
              display: block;

              p {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .en_US {
      .main .content_warp .content .games ul li a div {
        width: 480px;

        h3 {
          font-size: 14px;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .secondary_page {
    .layout {
      .main {
        .banner_warp {
          margin-top: 0px;
          height: 360px;

          .secondary_page_title {
            padding: 70px 0 0 0;
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 501px) and (max-width: 675px) {}

@media only screen and (min-width: 0px) and (max-width: 675px) {
  .page {
    min-width: 320px;

    .layout {
      .header {
        display: none;
      }

      .header_2 {
        display: block;
        z-index: 999;

        .header_2_content {
          a {
            .logo {
              img {
                width: 100px;
                position: relative;
                top: 4px;
              }
            }
          }

          .language {
            ul {
              li {
                font-size: 14px;

                a {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .main {
        .banner_warp {
          margin-top: 70px;
          height: auto;

          .banner_img {
            // transform: translate(-50%,-50%) scale(.6);
          }

          .video-img {
            transform: translate(-50%, -50%) scale(.35);
          }

          .company_img {
            transform: translate(-50%, -50%) scale(.6);
            margin: 12px 0 0 -120px;
          }

          .cooperation_img {
            transform: translate(-50%, -50%) scale(.6);
            margin: 12px 0 0 0;
          }

          .game_img {
            transform: translate(-50%, -50%) scale(.75);
            margin-top: 50px;
          }

          .new_img {
            transform: translate(-50%, -50%) scale(.6);
          }

          .notice_img {
            transform: translate(-50%, -50%) scale(.6);
          }

          .player_btn {
            width: 85px;
          }
        }

        .intro-warp {
          height: 300px;
          margin: 35px 0;

          &>div {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;

            div {
              padding-top: 70px;
            }
          }

          div {
            img {
              width: 240px;
            }

            h3 {
              margin-top: 5px;
              font-size: 22px;
              line-height: 28px;
            }

            h4 {
              margin-top: 0px;
              font-size: 14px;
              line-height: 20px;
            }

            p {
              margin-top: 4px;
              font-size: 14px;
              line-height: 20px;
            }

            .game_icons {
              display: none;
            }

            .slash {
              display: block;
              padding: 0;
              margin-top: 6px;
              text-align: center;

              img {
                width: 20px;
              }
            }
          }
        }

        .content_warp {
          .sub-nav {
            height: 60px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
              display: none;
            }

            ul {
              position: relative;
              left: -8px;
              width: 100%;
              min-width: 500px;
              // width: fit-content; // 兼容问题暂时注释
              // min-width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              right: 0;
              justify-content: flex-start;

              li {
                padding: 0 8px;

                a {
                  line-height: 60px;
                  white-space: nowrap;

                  &:hover {
                    color: #939393;

                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .sub-nav-shrink {
            height: 30px;
          }

          .content {
            padding-bottom: 30px;

            .about_us {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 30px 0 0 0;

              img {
                width: 100px;
              }

              p {
                text-indent: 2em;
                margin-top: 15px;
                font-size: 14px;
                line-height: 28px;
              }
            }

            .manage_team {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              max-width: none;
              margin: 30px 0 0 0;

              ul {
                li {
                  flex-direction: column;
                  align-items: center;
                  padding: 0px 0 15px 0;

                  img {
                    width: 100%;
                  }

                  &>div {
                    margin: 24px 0 0 0;

                    h4 {
                      font-size: 14px;
                      text-align: left;
                      display: block;
                      line-height: 28px;
                      margin-bottom: 12px;

                      b {
                        font-size: 14px;
                      }

                      .separated {
                        margin: 0 5px 0 5px;
                      }
                    }

                    p {
                      text-indent: 2em;
                      font-size: 14px;
                      line-height: 28px;
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }

            .company {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px;
              margin: 30px 0 0 0;

              div {
                width: 100%;
                // overflow-x: scroll;
                // &::-webkit-scrollbar {
                //   display: none;
                // }

                img {
                  display: none;
                }

                .organization_chart_mobile {
                  display: inline-block;
                }
              }

              p {
                text-align: center;
                font-size: 14px;
              }
            }

            .games {
              display: none;
            }

            .game-mobile {
              display: block;
            }

            .new_list {
              width: 100%;
              box-sizing: border-box;
              padding: 0 20px 8px 20px;

              li {
                a {
                  font-size: 14px;
                  height: 26px;
                  line-height: 26px;
                  padding: 12px 0 8px 0;

                  .new_title {
                    font-weight: 400;
                  }
                }

                &:first-child {
                  a {
                    padding-top: 0;
                  }
                }
              }
            }

            .new_detail {
              width: 100%;
              padding: 0 20px;
              box-sizing: border-box;

              .bread_crumb {
                font-size: 14px;
                margin-bottom: 8px;
              }

              .detail_content {
                .time {
                  font-size: 14px;
                  margin-bottom: 8px;
                }

                h3 {
                  font-size: 16px;
                  margin-bottom: 16px;
                }

                p {
                  font-size: 14px;
                  margin-bottom: 8px;
                }
              }
            }

            .notice_list {
              width: 100%;
              box-sizing: border-box;
              padding: 20px 20px 8px 20px;

              li {
                a {
                  font-size: 14px;
                  // height: 26px;
                  line-height: 26px;
                  padding: 12px 0 8px 20px;

                  .new_title {
                    font-weight: 400;
                  }

                  &::before {
                    width: 15px;
                    height: 18px;
                    background-size: 100%;
                  }
                }

                &:first-child {
                  a {
                    padding-top: 0;

                    &::before {
                      margin-top: -6px;
                    }
                  }
                }
              }
            }

            .investor_info {
              width: 100%;
              padding: 0 20px;

              h3 {
                font-size: 22px;
                line-height: 28px;
              }

              h4 {
                margin-top: 0px;
                font-size: 14px;
                line-height: 20px;
              }

              .investor_info_content {
                flex-direction: column;
                margin-top: 24px;

                &>div {
                  width: 100%;
                  height: auto;
                  min-height: 80px;
                  padding: 16px 10px 16px 50px;

                  p {
                    font-size: 14px;

                    img {
                      transform: scale(0.8);
                    }
                  }

                  .location {
                    height: auto;
                  }

                  p:last-child {
                    margin-top: 10px;
                  }

                  &:last-child {
                    margin-top: 24px;
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        padding: 30px 0 0 0;
        height: 325px;
        overflow: hidden;

        .center-content {
          width: calc(100% - 40px);

          .content-img {
            text-align: center;
          }

          .content-word {
            margin-top: 10px;
            flex-direction: column;
            align-items: center;

            .statements {
              width: 170px;
              margin-bottom: 10px;

              dl {
                dt {
                  margin-bottom: 8px;
                }

                dd {
                  .wechat-dialog {
                    z-index: 99;
                    bottom: auto;
                    top: 100%;
                    left: 0;
                  }
                }
              }
            }

            .info-list {
              display: none;
            }

            .info-list-mobile {
              display: block;

              p {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .en_US {
      .main .intro-warp {
        height: 360px;
      }
    }
  }

  .secondary_page {
    .layout {
      .main {
        .banner_warp {
          margin-top: 0px;
          height: 260px;

          .secondary_page_title {
            padding: 70px 0 0 0;

            .l_title {
              font-size: 30px;
              line-height: 30px;
            }
          }
        }
      }
    }

    .en_US {
      .main {
        .banner_warp {
          margin-top: 0px;
          height: 260px;

          .secondary_page_title {
            padding: 70px 0 0 0;

            .en_title {
              font-size: 24px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }

  //视频全屏样式
  .full_screen {
    overflow: hidden;

    .page .layout .main .playing {
      background-color: #000;

      video {
        height: auto;
      }
    }
  }
}
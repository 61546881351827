.autoLineFeed {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}
.omit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
body {
  background-color: #fafafa;
}
body .page {
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  transition: all 0.75s;
}
.achieve .page {
  height: auto;
  overflow: auto;
  opacity: 1;
}
.page .layout {
  position: relative;
  overflow: hidden;
}
.page .layout .header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 84px;
  z-index: 1000;
  background: #000;
}
.page .layout .header .header-content {
  position: absolute;
  left: 50%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 72%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  box-sizing: border-box;
  padding: 15px 0 0 0;
}
.page .layout .header .header-content .logo {
  cursor: pointer;
}
.page .layout .header .header-content .logo img {
  width: 170px;
  height: 44px;
  position: relative;
  top: 5px;
}
.page .layout .header .header-content .nav > ul {
  display: flex;
  width: 550px;
  justify-content: space-between;
  align-items: center;
}
.page .layout .header .header-content .nav > ul li {
  display: inline-flex;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  align-items: center;
  position: relative;
}
.page .layout .header .header-content .nav > ul li:hover {
  color: #507fff;
}
.page .layout .header .header-content .nav > ul li > a {
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}
.page .layout .header .header-content .nav > ul li > a:hover {
  color: #507fff;
}
.page .layout .header .header-content .nav > ul li > a:hover::before {
  content: "";
  position: absolute;
  bottom: -35px;
  left: 50%;
  width: 130%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  border-bottom: 2px solid #507fff;
}
.page .layout .header .header-content .nav > ul .active > a::before {
  content: "";
  position: absolute;
  bottom: -35px;
  left: 50%;
  width: 130%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  border-bottom: 2px solid #507fff;
}
.page .layout .header .header-content .nav > ul .product > a > b {
  position: relative;
  top: 2px;
  left: 5px;
  display: inline-block;
  border: 4px solid transparent;
  border-top: 4px solid #fff;
}
.page .layout .header .header-content .nav > ul .product > a:hover b {
  border-top: 4px solid #507fff;
}
.page .layout .header .header-content .nav > ul .product .game_list {
  display: none;
  position: absolute;
  top: 51px;
  left: 50%;
  width: 170px;
  background: rgba(0, 0, 0, 0.8);
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}
.page .layout .header .header-content .nav > ul .product .game_list dd {
  display: block;
  text-align: center;
}
.page .layout .header .header-content .nav > ul .product .game_list dd a {
  color: #fff;
  display: block;
  line-height: 36px;
  height: 36px;
}
.page .layout .header .header-content .nav > ul .product .game_list dd a:hover {
  color: #507fff;
}
.page .layout .header .header-content .nav > ul .product:hover .game_list {
  display: block;
}
.page .layout .header .header-content .nav > ul .product:hover::after {
  display: block;
  position: absolute;
  content: "";
  width: 110px;
  height: 40px;
  top: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}
.page .layout .header .header-content .language {
  position: absolute;
  right: -6%;
  transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
}
.page .layout .header .header-content .language ul {
  display: flex;
}
.page .layout .header .header-content .language ul li {
  padding: 0 4px;
  font-size: 12px;
  color: #8e8e8e;
}
.page .layout .header .header-content .language ul li a {
  font-size: 12px;
  color: #8e8e8e;
}
.page .layout .header .header-content .language ul li a:hover {
  color: #fff;
}
.page .layout .header .header-content .language ul .selected a {
  color: #fff;
}
.page .layout .header_2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 10;
  background: #000;
  display: none;
}
.page .layout .header_2 .header_2_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.page .layout .header_2 .header_2_content .header_2_nav {
  position: absolute;
  top: -400px;
  z-index: 0;
  width: 100%;
  left: 0;
  background: rgba(18, 29, 31, 0.9);
  padding-bottom: 10px;
  transition: all 0.5s ease;
}
.page .layout .header_2 .header_2_content .header_2_nav li {
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.page .layout .header_2 .header_2_content .header_2_nav li a {
  color: #fff;
  font-size: 16px;
  display: block;
}
.page .layout .header_2 .header_2_content .header_2_nav li a:hover {
  color: #507fff;
}
.page .layout .header_2 .header_2_content > a {
  position: relative;
  z-index: 10;
}
.page .layout .header_2 .header_2_content > a .logo {
  margin-left: 20px;
}
.page .layout .header_2 .header_2_content > a .logo img {
  width: 136px;
}
.page .layout .header_2 .header_2_content .language {
  position: relative;
  z-index: 10;
}
.page .layout .header_2 .header_2_content .language ul {
  display: inline-flex;
  align-items: center;
}
.page .layout .header_2 .header_2_content .language ul li {
  display: inline-block;
  padding: 0 4px;
  font-size: 12px;
  color: #8e8e8e;
}
.page .layout .header_2 .header_2_content .language ul li a {
  font-size: 12px;
  color: #8e8e8e;
}
.page .layout .header_2 .header_2_content .language ul .selected a {
  color: #fff;
}
.page .layout .header_2 .header_2_content .header_2_nav_box {
  position: relative;
  z-index: 10;
  width: 64px;
  cursor: pointer;
  transition: all 1s;
}
.page .layout .header_2 .header_2_content .header_2_nav_box .header_2_nav_more {
  padding: 20px 20px;
  cursor: pointer;
}
.page .layout .header_2 .header_2_content .header_2_nav_box .header_2_nav_more .icon-bar {
  display: block;
  margin: 6px 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.5s ease;
}
.page .layout .header_2 .open .header_2_nav {
  top: 100%;
}
.page .layout .header_2 .open .header_2_nav_box .header_2_nav_more {
  background: rgba(18, 29, 31, 0.9);
}
.page .layout .header_2 .open .header_2_nav_box .header_2_nav_more .top {
  transition: all 0.5s ease;
  transform: translateY(10px) rotateZ(45deg);
  -webkit-transform: translateY(10px) rotateZ(45deg);
}
.page .layout .header_2 .open .header_2_nav_box .header_2_nav_more .middle {
  width: 0;
  transition: all 0.5s ease;
}
.page .layout .header_2 .open .header_2_nav_box .header_2_nav_more .bottom {
  transform: translateY(-6px) rotateZ(-45deg);
  -webkit-transform: translateY(-6px) rotateZ(-45deg);
  transition: all 0.5s ease;
}
.page .layout .main .banner_warp {
  position: relative;
  width: 100%;
  margin-top: 84px;
  height: calc(100vh - 84px - 220px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page .layout .main .banner_warp .banner_img {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 3;
}
.page .layout .main .banner_warp .player_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 5;
  cursor: pointer;
}
.page .layout .main .banner_warp::after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 29, 35, 0.55);
  z-index: 4;
  cursor: pointer;
}
.page .layout .main .playing {
  position: relative;
}
.page .layout .main .playing .banner_img {
  display: none;
}
.page .layout .main .playing .player_btn {
  display: none;
}
.page .layout .main .playing::after {
  display: none;
}
.page .layout .main .playing .video-mask {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  cursor: pointer;
  background: rgba(25, 29, 35, 0.55);
}
.page .layout .main .playing .close-full-video {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;
  background-color: #585757;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  opacity: 0.3;
  z-index: 999;
}
.page .layout .main .intro-warp {
  width: 100%;
  height: auto;
  background: url("/images/intro-bg-7f2f86c4a1.png") center center no-repeat;
  background-size: cover;
  margin: 90px 0;
  overflow: hidden;
}
.page .layout .main .intro-warp > div {
  width: 96%;
  margin: 0 auto;
}
.page .layout .main .intro-warp > div div {
  text-align: center;
  padding-top: 80px;
}
.page .layout .main .intro-warp > div h3 {
  margin-top: 30px;
  font-size: 36px;
  color: #fff;
  text-align: center;
}
.page .layout .main .intro-warp > div h4 {
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.page .layout .main .intro-warp > div p {
  margin-top: 25px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.page .layout .main .intro-warp > div .slash {
  display: none;
}
.page .layout .main .intro-warp > div .game_icons {
  max-height: 300px;
  overflow: hidden;
  padding-top: 50px;
}
.page .layout .main .intro-warp > div .game_icons img {
  width: 100%;
}
.page .layout .main .content_warp .sub-nav {
  width: 100%;
  height: 70px;
}
.page .layout .main .content_warp .sub-nav ul {
  width: 72%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: -15px;
  border-bottom: 2px solid #dddddd;
}
.page .layout .main .content_warp .sub-nav ul li {
  color: #939393;
  padding: 0 15px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.page .layout .main .content_warp .sub-nav ul li a {
  display: inline-block;
  height: 100%;
  line-height: 70px;
  font-size: 14px;
  font-weight: 500;
  color: #939393;
}
.page .layout .main .content_warp .sub-nav ul li a:hover {
  color: #507fff;
}
.page .layout .main .content_warp .sub-nav ul li a:hover::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #507fff;
}
.page .layout .main .content_warp .sub-nav ul .active a {
  color: #507fff;
}
.page .layout .main .content_warp .sub-nav ul .active a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #507fff;
}
.page .layout .main .content_warp .content {
  padding-bottom: 70px;
}
.page .layout .main .content_warp .content .new_list {
  width: 72%;
  max-width: 1050px;
  margin: 0 auto;
  padding-bottom: 25px;
}
.page .layout .main .content_warp .content .new_list li a {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
}
.page .layout .main .content_warp .content .new_list li a .new_title {
  flex: 1;
  color: #333333;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page .layout .main .content_warp .content .new_list li a .new_time {
  margin-left: 30px;
  color: #5e5e5e;
}
.page .layout .main .content_warp .content .new_list li a:hover .new_title {
  color: #507fff;
}
.page .layout .main .content_warp .content .investor_info {
  width: 980px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 20px;
}
.page .layout .main .content_warp .content .investor_info h3 {
  font-size: 36px;
  line-height: 36px;
  color: #293241;
  text-align: center;
}
.page .layout .main .content_warp .content .investor_info h4 {
  font-size: 18px;
  line-height: 18px;
  color: #293241;
  margin-top: 10px;
  text-align: center;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 54px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div {
  width: 480px;
  height: 120px;
  background-color: #fff;
  box-shadow: 4px 0 6px rgba(4, 0, 0, 0.05);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 26px 50px 0 70px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div p {
  position: relative;
  font-size: 16px;
  line-height: 20px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div p img {
  position: absolute;
  top: 0;
  left: -35px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div p .mail_img {
  top: 2px;
  left: -40px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div p .email {
  margin-left: 0px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .l_address {
  height: 40px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .postCode {
  margin-top: 10px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .postCode img {
  top: 2px;
  left: -39px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .l_postCode {
  width: 65px;
  display: inline-block;
  text-align-last: justify;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .location {
  height: 40px;
}
.page .layout .main .content_warp .content .investor_info .investor_info_content > div .text {
  line-height: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: #000;
}
.page .layout .main .content_warp .content .notice_list {
  width: 72%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 0 10px 0;
  min-height: 100px;
}
.page .layout .main .content_warp .content .notice_list li a {
  position: relative;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 25px 0 25px 50px;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
}
.page .layout .main .content_warp .content .notice_list li a .new_title {
  flex: 1;
  color: #333333;
}
.page .layout .main .content_warp .content .notice_list li a .new_time {
  margin-left: 30px;
  color: #5e5e5e;
}
.page .layout .main .content_warp .content .notice_list li a:hover .new_title {
  color: #507fff;
}
.page .layout .main .content_warp .content .notice_list li a::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  content: "";
  display: block;
  width: 21px;
  height: 24px;
  background: url("/images/download-43db22bb77.png") no-repeat;
}
.page .layout .main .content_warp .content .load-more {
  display: none;
  width: 72%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px 0 0;
}
.page .layout .main .content_warp .content .load-more div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page .layout .main .content_warp .content .load-more a {
  padding: 8px 12px;
  border: 1px solid #5e5e5e;
  border-radius: 5px;
  color: #5e5e5e;
}
.page .layout .main .content_warp .content .about_us {
  width: 860px;
  margin: 120px auto 50px auto;
}
.page .layout .main .content_warp .content .about_us p {
  margin-top: 50px;
  font-size: 16px;
  line-height: 36px;
  text-align: justify;
}
.page .layout .main .content_warp .content .manage_team {
  width: 72%;
  max-width: 694px;
  margin: 100px auto 30px auto;
}
.page .layout .main .content_warp .content .manage_team ul li {
  display: flex;
  padding: 30px 0 10px 0;
  border-bottom: 1px solid #ddd;
}
.page .layout .main .content_warp .content .manage_team ul li img {
  width: 296px;
}
.page .layout .main .content_warp .content .manage_team ul li > div {
  margin: 0 0 0 0px;
}
.page .layout .main .content_warp .content .manage_team ul li > div h4 {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-bottom: 28px;
}
.page .layout .main .content_warp .content .manage_team ul li > div h4 b {
  font-size: 18px;
}
.page .layout .main .content_warp .content .manage_team ul li > div h4 .separated {
  margin: 0 10px 0 15px;
}
.page .layout .main .content_warp .content .manage_team ul li > div p {
  font-size: 16px;
  line-height: 32px;
  color: #000;
  text-align: justify;
  margin-bottom: 10px;
}
.page .layout .main .content_warp .content .company {
  width: 72%;
  max-width: 1100px;
  margin: 120px auto 60px auto;
}
.page .layout .main .content_warp .content .company p {
  font-size: 16px;
  line-height: 36px;
}
.page .layout .main .content_warp .content .company div {
  margin: 10px auto 0 auto;
  width: 100%;
  text-align: center;
}
.page .layout .main .content_warp .content .company div img {
  width: 100%;
  max-width: 1029px;
}
.page .layout .main .content_warp .content .company .organization_chart_mobile {
  display: none;
}
.page .layout .main .content_warp .content .games {
  width: 100%;
}
.page .layout .main .content_warp .content .games ul li {
  font-size: 0;
  position: relative;
  overflow: hidden;
  height: 204px;
}
.page .layout .main .content_warp .content .games ul li .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: all ease-in-out 0.4s;
}
.page .layout .main .content_warp .content .games ul li .bg-copy {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 5;
}
.page .layout .main .content_warp .content .games ul li::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  z-index: 8;
  background-color: rgba(25, 29, 35, 0.55);
}
.page .layout .main .content_warp .content .games ul li:hover .bg {
  transform: translate(-50%, -50%) scale(1.1);
}
.page .layout .main .content_warp .content .games ul li a {
  width: 850px;
  margin: 0 auto;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}
.page .layout .main .content_warp .content .games ul li a > div {
  width: 600px;
}
.page .layout .main .content_warp .content .games ul li a h3 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.page .layout .main .content_warp .content .games ul li a p {
  font-size: 12px;
  line-height: 19px;
  color: #fff;
  text-align: justify;
  margin-bottom: 5px;
}
.page .layout .main .content_warp .content .games ul li .game-5-logo {
  position: relative;
  margin-left: -26px;
}
.page .layout .main .content_warp .content .games ul li .game-6-logo {
  position: relative;
  left: -10px;
  top: -2px;
}
.page .layout .main .content_warp .content .games ul li .game-11-logo {
  position: relative;
  margin-left: 16px;
}
.page .layout .main .content_warp .content .games ul li .game-10-logo {
  position: relative;
  margin-left: 16px;
}
.page .layout .main .content_warp .content .game-mobile {
  display: none;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.page .layout .main .content_warp .content .game-mobile ul li {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin-bottom: 30px;
}
.page .layout .main .content_warp .content .game-mobile ul li:last-child {
  margin-bottom: 0px;
}
.page .layout .main .content_warp .content .game-mobile ul li a .img-box {
  position: relative;
  font-size: 0;
}
.page .layout .main .content_warp .content .game-mobile ul li a .img-box img {
  width: 100%;
}
.page .layout .main .content_warp .content .game-mobile ul li a .img-box .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(18, 29, 31, 0.3);
  transition: all 0.25s;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info {
  padding: 10px 16px 14px 16px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info > div:first-child {
  flex: 1;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info h3 {
  color: #090D12;
  font-weight: bold;
  font-size: 20px;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info p {
  font-size: 12px;
  color: #090D12;
  margin-top: 8px;
  line-height: 20px;
  text-align: justify;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info .more {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}
.page .layout .main .content_warp .content .game-mobile ul li a .info .more span {
  width: 8px;
  height: 8px;
  margin-left: -4px;
  border: 1px solid #d2d2d2;
  display: block;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.page .layout .main .content_warp .content .new_detail {
  width: 72%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 0 0px 0;
}
.page .layout .main .content_warp .content .new_detail .bread_crumb {
  line-height: 24px;
  margin-bottom: 25px;
}
.page .layout .main .content_warp .content .new_detail .bread_crumb a {
  color: #000;
}
.page .layout .main .content_warp .content .new_detail .bread_crumb a:hover {
  color: #507fff;
}
.page .layout .main .content_warp .content .new_detail .bread_crumb span {
  color: #507fff;
}
.page .layout .main .content_warp .content .new_detail .detail_content .time {
  color: #507fff;
  margin-bottom: 20px;
}
.page .layout .main .content_warp .content .new_detail .detail_content h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.page .layout .main .content_warp .content .new_detail .detail_content p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 14px;
}
.page .layout .main .content_warp .content .new_detail .detail_content img {
  display: block;
  margin: 0 auto;
  width: 90%;
}
.page .layout .footer {
  width: 100%;
  height: 234px;
  box-sizing: border-box;
  padding: 38px 0 0 0;
  background-color: #090d12;
}
.page .layout .footer .center-content {
  width: 72%;
  margin: 0 auto;
}
.page .layout .footer .center-content .content-img {
  text-align: right;
  display: none;
}
.page .layout .footer .center-content .content-word {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
.page .layout .footer .center-content .content-word .statements {
  display: flex;
  width: 200px;
  justify-content: space-between;
}
.page .layout .footer .center-content .content-word .statements dl dt {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 16px;
}
.page .layout .footer .center-content .content-word .statements dl dd {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 26px;
  cursor: pointer;
  position: relative;
}
.page .layout .footer .center-content .content-word .statements dl dd a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}
.page .layout .footer .center-content .content-word .statements dl dd a:hover {
  color: #507fff;
}
.page .layout .footer .center-content .content-word .statements dl dd:hover {
  color: #507fff;
}
.page .layout .footer .center-content .content-word .statements dl dd .wechat-dialog {
  display: none;
  width: 110px;
  text-align: center;
  position: absolute;
  bottom: -40px;
  left: 35px;
  padding: 5px;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}
.page .layout .footer .center-content .content-word .statements dl .pc-wechat:hover .wechat-dialog {
  display: block;
}
.page .layout .footer .center-content .content-word .info-list {
  position: relative;
  right: -10px;
  top: -2px;
}
.page .layout .footer .center-content .content-word .info-list p {
  font-size: 12px;
  color: #fff;
  line-height: 25px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page .layout .footer .center-content .content-word .info-list p a {
  color: #fff;
}
.page .layout .footer .center-content .content-word .info-list p a:hover {
  color: #507fff;
}
.page .layout .footer .center-content .content-word .info-list-mobile {
  display: none;
}
.page .layout .footer .center-content .content-word .info-list-mobile p {
  font-size: 12px;
  color: #fff;
  line-height: 25px;
}
.page .layout .footer .center-content .content-word .info-list-mobile p a {
  color: #fff;
}
.page .layout .footer .center-content .content-word .info-list-mobile p a:hover {
  color: #507fff;
}
.page .en_US .header .header-content .nav > ul {
  width: 700px;
}
.page .en_US .header .header-content .nav > ul .active a::before {
  width: 100%;
}
.page .en_US .header .header-content .nav > ul li a:hover:before {
  width: 100%;
}
.page .en_US .header .header-content .nav > ul .product .game_list {
  width: 190px;
}
.page .en_US .main .content_warp .content .new_list li a {
  height: auto !important;
}
.page .en_US .main .content_warp .content .new_list li a .new_title {
  white-space: initial;
  line-height: 32px;
}
.page .en_US .main .content_warp .content .new_list li a .new_time {
  line-height: 32px;
}
.page .en_US .main .content_warp .content .games ul li a {
  width: 1100px;
}
.page .en_US .main .content_warp .content .games ul li a h3 {
  margin-bottom: 5px;
}
.page .en_US .main .content_warp .content .games ul li a div {
  width: 850px;
}
.page .en_US .main .content_warp .content .games ul .game-bg-1 a div p {
  margin-bottom: 0;
}
.page .en_US .main .content_warp .content .company div {
  width: 100%;
}
.page .en_US .main .content_warp .content .company div img {
  max-width: 1095px;
}
.secondary_page .layout .header {
  background: rgba(0, 0, 0, 0.8);
}
.secondary_page .layout .header_2 {
  background: rgba(0, 0, 0, 0.8);
}
.secondary_page .layout .main .banner_warp {
  width: 100%;
  margin-top: 0px;
  height: 480px;
  display: block;
}
.secondary_page .layout .main .banner_warp .banner_img {
  width: 1920px;
  height: 480px;
  position: absolute;
  z-index: 0;
}
.secondary_page .layout .main .banner_warp .secondary_page_title {
  box-sizing: border-box;
  height: 100%;
  padding: 84px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
}
.secondary_page .layout .main .banner_warp .secondary_page_title .l_title {
  font-size: 42px;
  font-weight: bold;
  color: #fff;
}
.secondary_page .layout .main .banner_warp .secondary_page_title .en_title {
  font-size: 42px;
  color: #fff;
  font-weight: 500;
  display: none;
}
.secondary_page .layout .main .banner_warp::after {
  display: none;
}
.secondary_page .en_US .main .banner_warp .secondary_page_title .l_title {
  display: none;
}
.secondary_page .en_US .main .banner_warp .secondary_page_title .en_title {
  display: block;
}
.full_screen {
  overflow: hidden;
}
.full_screen .page .layout .main .playing {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  margin-top: 0;
}
.full_screen .page .layout .main .playing .video-mask {
  cursor: context-menu;
}
.full_screen .page .layout .main .playing .close-full-video {
  display: block;
}
.full_screen .page .layout .header {
  z-index: 10;
}
@media only screen and (min-width: 1281px) and (max-width: 1550px) {
  .page .en_US .main .intro-warp div p {
    width: 690px;
    margin: 15px auto 0 auto;
  }
  .page .main .intro-warp .intro-img {
    width: 380px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .page .layout .header {
    display: none;
  }
  .page .layout .header_2 {
    display: block;
    z-index: 999;
  }
  .page .layout .main .banner_warp {
    margin-top: 70px;
    height: 42vw;
  }
  .page .layout .main .intro-warp {
    height: auto;
    margin: 72px 0;
  }
  .page .layout .main .intro-warp > div {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .page .layout .main .intro-warp > div div {
    padding-top: 60px;
  }
  .page .layout .main .intro-warp div img {
    width: 310px;
  }
  .page .layout .main .intro-warp div h3 {
    margin-top: 20px;
    font-size: 28px;
  }
  .page .layout .main .intro-warp div h4 {
    font-size: 16px;
  }
  .page .layout .main .intro-warp div p {
    margin-top: 16px;
    font-size: 18px;
  }
  .page .layout .main .intro-warp div .slash {
    margin-top: 15px;
    text-align: center;
  }
  .page .layout .main .intro-warp div .slash img {
    width: 40px;
  }
  .page .layout .main .content_warp .sub-nav ul {
    min-width: 860px;
  }
  .page .layout .main .content_warp .content .games ul li a {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .page .layout .main .content_warp .content .games ul li a > div {
    width: 552px;
  }
  .page .layout .main .content_warp .content .notice_list,
  .page .layout .main .content_warp .content .new_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
  }
  .page .layout .footer .center-content {
    width: calc(100% - 40px);
  }
  .page .en_US .main .content_warp .content .games ul li a h3 {
    margin-bottom: 0;
  }
  .page .en_US .main .content_warp .content .games ul li a div {
    width: 750px;
  }
  .page .en_US .main .content_warp .content .games ul li a div p {
    line-height: 18px;
  }
  .secondary_page .layout .main .banner_warp {
    margin-top: 0px;
    height: 480px;
  }
  .secondary_page .layout .main .banner_warp .secondary_page_title {
    padding: 70px 0 0 0;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .page .layout .header {
    display: none;
  }
  .page .layout .header_2 {
    display: block;
    z-index: 999;
  }
  .page .layout .main .banner_warp {
    margin-top: 70px;
    height: 42vw;
  }
  .page .layout .main .intro-warp {
    height: 420px;
    margin: 64px 0;
  }
  .page .layout .main .intro-warp > div {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .page .layout .main .intro-warp > div div {
    padding-top: 50px;
  }
  .page .layout .main .intro-warp div img {
    width: 280px;
  }
  .page .layout .main .intro-warp div h3 {
    margin-top: 14px;
    font-size: 24px;
  }
  .page .layout .main .intro-warp div h4 {
    font-size: 14px;
  }
  .page .layout .main .intro-warp div p {
    margin-top: 10px;
    font-size: 16px;
  }
  .page .layout .main .intro-warp div .slash {
    display: none;
    margin-top: 8px;
    padding: 0;
    text-align: center;
  }
  .page .layout .main .intro-warp div .slash img {
    width: 35px;
  }
  .page .layout .main .content_warp .sub-nav {
    width: 100%;
  }
  .page .layout .main .content_warp .sub-nav ul {
    width: 100%;
  }
  .page .layout .main .content_warp .sub-nav ul li:last-child {
    margin-right: 10px;
  }
  .page .layout .main .content_warp .content .games ul li a {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .page .layout .main .content_warp .content .games ul li a > div {
    width: 512px;
  }
  .page .layout .main .content_warp .content .games ul li a p {
    margin-bottom: 0;
  }
  .page .layout .main .content_warp .content .games ul li a .game-6-logo {
    width: 162px;
    margin-left: 16px;
  }
  .page .layout .main .content_warp .content .games ul li a .game-8-logo,
  .page .layout .main .content_warp .content .games ul li a .game-9-logo {
    width: 180px;
  }
  .page .layout .main .content_warp .content .games ul li a .game-11-logo {
    height: 100px;
  }
  .page .layout .main .content_warp .content .games ul li a .game-10-logo {
    width: 100px;
    margin-left: 40px;
  }
  .page .layout .main .content_warp .content .notice_list,
  .page .layout .main .content_warp .content .new_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
  }
  .page .layout .main .content_warp .content .manage_team {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
    margin: 70px 0 0 0;
  }
  .page .layout .main .content_warp .content .about_us {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 70px 0 0 0;
  }
  .page .layout .main .content_warp .content .about_us p {
    margin-top: 35px;
  }
  .page .layout .main .content_warp .content .company {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 70px 0 0 0;
  }
  .page .layout .main .content_warp .content .new_detail {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .page .layout .main .content_warp .content .investor_info {
    width: 94%;
    min-height: calc(100vh - 520px - 480px - 140px);
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div {
    width: 49%;
    padding: 26px 10px 0 45px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p {
    font-size: 15px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p img {
    left: -24px;
    transform: scale(0.9);
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p .mail_img {
    left: -32px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div .postCode img {
    left: -28px;
  }
  .page .layout .footer {
    padding: 30px 0 0 0;
    height: 300px;
  }
  .page .layout .footer .center-content {
    width: calc(100% - 40px);
  }
  .page .layout .footer .center-content .content-img {
    text-align: center;
  }
  .page .layout .footer .center-content .content-word {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .page .layout .footer .center-content .content-word .statements {
    width: 170px;
    margin-bottom: 10px;
  }
  .page .layout .footer .center-content .content-word .statements dl dt {
    margin-bottom: 8px;
  }
  .page .layout .footer .center-content .content-word .info-list {
    display: none;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile {
    display: block;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile p {
    text-align: center;
  }
  .page .en_US .main .content_warp .content .games ul li a div {
    width: 585px;
  }
  .page .en_US .main .content_warp .content .games ul li a div h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .page .en_US .main .content_warp .content .games ul li a div .l_game_1_title {
    margin-bottom: 0;
    line-height: 14px;
  }
  .page .en_US .main .content_warp .content .games ul li a div p {
    font-size: 12px;
    line-height: 16px;
  }
  .secondary_page .layout .main .banner_warp {
    margin-top: 0px;
    height: 480px;
  }
  .secondary_page .layout .main .banner_warp .secondary_page_title {
    padding: 70px 0 0 0;
  }
}
@media only screen and (min-width: 769px) and (max-width: 850px) {
  .page .en_US .main .content_warp .content .games ul li.game-bg-1 a img {
    width: 134.4px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-2 a img {
    width: 132.3px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-3 a img {
    width: 126.7px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-4 a img {
    width: 128.1px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-5 a img {
    width: 161.7px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-6 a img {
    width: 147.7px;
    max-width: 132px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-7 a img {
    width: 149.8px;
    max-width: 132px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-8 a img {
    width: 137.9px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-9 a img {
    width: 138.6px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-10 a img {
    width: 98.7px;
    margin-left: 16px;
  }
  .page .en_US .main .content_warp .content .games ul li.game-bg-11 a img {
    width: 114.8px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 676px) and (max-width: 768px) {
  .page .layout .header {
    display: none;
  }
  .page .layout .header_2 {
    display: block;
    z-index: 999;
  }
  .page .layout .main .banner_warp {
    margin-top: 70px;
    height: 42vw;
  }
  .page .layout .main .intro-warp {
    height: 420px;
    margin: 64px 0;
  }
  .page .layout .main .intro-warp > div {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .page .layout .main .intro-warp > div div {
    padding-top: 55px;
  }
  .page .layout .main .intro-warp div img {
    width: 260px;
  }
  .page .layout .main .intro-warp div h3 {
    margin-top: 16px;
    font-size: 24px;
  }
  .page .layout .main .intro-warp div h4 {
    font-size: 14px;
  }
  .page .layout .main .intro-warp div p {
    margin-top: 20px;
    font-size: 16px;
  }
  .page .layout .main .intro-warp div .slash {
    display: none;
    padding: 0;
    margin-top: 8px;
    text-align: center;
  }
  .page .layout .main .intro-warp div .slash img {
    width: 25px;
  }
  .page .layout .main .intro-warp div .game_icons {
    padding-top: 60px;
  }
  .page .layout .main .content_warp .sub-nav ul {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    right: 0;
    justify-content: center;
  }
  .page .layout .main .content_warp .content {
    padding-bottom: 30px;
  }
  .page .layout .main .content_warp .content .games {
    display: none;
  }
  .page .layout .main .content_warp .content .games ul li a {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .page .layout .main .content_warp .content .games ul li a img {
    width: 140px;
    margin-right: 20px;
  }
  .page .layout .main .content_warp .content .games ul li a .game-5-logo {
    left: 0;
  }
  .page .layout .main .content_warp .content .games ul li a .game-6-logo {
    left: 0;
  }
  .page .layout .main .content_warp .content .games ul li a > div {
    width: 512px;
  }
  .page .layout .main .content_warp .content .games ul li a p {
    margin-bottom: 0;
  }
  .page .layout .main .content_warp .content .game-mobile {
    display: block;
  }
  .page .layout .main .content_warp .content .notice_list,
  .page .layout .main .content_warp .content .new_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
  }
  .page .layout .main .content_warp .content .manage_team {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .manage_team ul li {
    flex-direction: column;
    align-items: center;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div {
    margin: 20px 0 0 0;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div h4 {
    text-align: left;
    display: block;
  }
  .page .layout .main .content_warp .content .about_us {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .about_us img {
    width: 136px;
  }
  .page .layout .main .content_warp .content .about_us p {
    margin-top: 15px;
  }
  .page .layout .main .content_warp .content .company {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .new_detail {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .page .layout .main .content_warp .content .investor_info {
    width: 100%;
    padding: 0 20px;
    min-height: calc(100vh - 520px - 480px - 140px);
  }
  .page .layout .main .content_warp .content .investor_info h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .page .layout .main .content_warp .content .investor_info h4 {
    margin-top: 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content {
    flex-direction: column;
    margin-top: 24px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div {
    width: 100%;
    height: 120px;
    padding: 26px 10px 0 60px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p:last-child {
    margin-top: 10px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div:last-child {
    margin-top: 24px;
  }
  .page .layout .footer {
    padding: 30px 0 0 0;
    height: 300px;
  }
  .page .layout .footer .center-content {
    width: calc(100% - 40px);
  }
  .page .layout .footer .center-content .content-img {
    text-align: center;
  }
  .page .layout .footer .center-content .content-word {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .page .layout .footer .center-content .content-word .statements {
    width: 170px;
    margin-bottom: 10px;
  }
  .page .layout .footer .center-content .content-word .statements dl dt {
    margin-bottom: 8px;
  }
  .page .layout .footer .center-content .content-word .info-list {
    display: none;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile {
    display: block;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile p {
    text-align: center;
  }
  .page .en_US .main .content_warp .content .games ul li a div {
    width: 480px;
  }
  .page .en_US .main .content_warp .content .games ul li a div h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .page .en_US .main .content_warp .content .games ul li a div p {
    font-size: 12px;
    line-height: 16px;
  }
  .secondary_page .layout .main .banner_warp {
    margin-top: 0px;
    height: 360px;
  }
  .secondary_page .layout .main .banner_warp .secondary_page_title {
    padding: 70px 0 0 0;
  }
}
@media only screen and (min-width: 0px) and (max-width: 675px) {
  .page {
    min-width: 320px;
  }
  .page .layout .header {
    display: none;
  }
  .page .layout .header_2 {
    display: block;
    z-index: 999;
  }
  .page .layout .header_2 .header_2_content a .logo img {
    width: 100px;
    position: relative;
    top: 4px;
  }
  .page .layout .header_2 .header_2_content .language ul li {
    font-size: 14px;
  }
  .page .layout .header_2 .header_2_content .language ul li a {
    font-size: 14px;
  }
  .page .layout .main .banner_warp {
    margin-top: 70px;
    height: auto;
  }
  .page .layout .main .banner_warp .video-img {
    transform: translate(-50%, -50%) scale(0.35);
  }
  .page .layout .main .banner_warp .company_img {
    transform: translate(-50%, -50%) scale(0.6);
    margin: 12px 0 0 -120px;
  }
  .page .layout .main .banner_warp .cooperation_img {
    transform: translate(-50%, -50%) scale(0.6);
    margin: 12px 0 0 0;
  }
  .page .layout .main .banner_warp .game_img {
    transform: translate(-50%, -50%) scale(0.75);
    margin-top: 50px;
  }
  .page .layout .main .banner_warp .new_img {
    transform: translate(-50%, -50%) scale(0.6);
  }
  .page .layout .main .banner_warp .notice_img {
    transform: translate(-50%, -50%) scale(0.6);
  }
  .page .layout .main .banner_warp .player_btn {
    width: 85px;
  }
  .page .layout .main .intro-warp {
    height: 300px;
    margin: 35px 0;
  }
  .page .layout .main .intro-warp > div {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .page .layout .main .intro-warp > div div {
    padding-top: 70px;
  }
  .page .layout .main .intro-warp div img {
    width: 240px;
  }
  .page .layout .main .intro-warp div h3 {
    margin-top: 5px;
    font-size: 22px;
    line-height: 28px;
  }
  .page .layout .main .intro-warp div h4 {
    margin-top: 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .page .layout .main .intro-warp div p {
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
  }
  .page .layout .main .intro-warp div .game_icons {
    display: none;
  }
  .page .layout .main .intro-warp div .slash {
    display: block;
    padding: 0;
    margin-top: 6px;
    text-align: center;
  }
  .page .layout .main .intro-warp div .slash img {
    width: 20px;
  }
  .page .layout .main .content_warp .sub-nav {
    height: 60px;
    overflow-x: scroll;
  }
  .page .layout .main .content_warp .sub-nav::-webkit-scrollbar {
    display: none;
  }
  .page .layout .main .content_warp .sub-nav ul {
    position: relative;
    left: -8px;
    width: 100%;
    min-width: 500px;
    box-sizing: border-box;
    padding: 0 20px;
    right: 0;
    justify-content: flex-start;
  }
  .page .layout .main .content_warp .sub-nav ul li {
    padding: 0 8px;
  }
  .page .layout .main .content_warp .sub-nav ul li a {
    line-height: 60px;
    white-space: nowrap;
  }
  .page .layout .main .content_warp .sub-nav ul li a:hover {
    color: #939393;
  }
  .page .layout .main .content_warp .sub-nav ul li a:hover::after {
    display: none;
  }
  .page .layout .main .content_warp .sub-nav-shrink {
    height: 30px;
  }
  .page .layout .main .content_warp .content {
    padding-bottom: 30px;
  }
  .page .layout .main .content_warp .content .about_us {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .about_us img {
    width: 100px;
  }
  .page .layout .main .content_warp .content .about_us p {
    text-indent: 2em;
    margin-top: 15px;
    font-size: 14px;
    line-height: 28px;
  }
  .page .layout .main .content_warp .content .manage_team {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    max-width: none;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .manage_team ul li {
    flex-direction: column;
    align-items: center;
    padding: 0px 0 15px 0;
  }
  .page .layout .main .content_warp .content .manage_team ul li img {
    width: 100%;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div {
    margin: 24px 0 0 0;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div h4 {
    font-size: 14px;
    text-align: left;
    display: block;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div h4 b {
    font-size: 14px;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div h4 .separated {
    margin: 0 5px 0 5px;
  }
  .page .layout .main .content_warp .content .manage_team ul li > div p {
    text-indent: 2em;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .page .layout .main .content_warp .content .company {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 30px 0 0 0;
  }
  .page .layout .main .content_warp .content .company div {
    width: 100%;
  }
  .page .layout .main .content_warp .content .company div img {
    display: none;
  }
  .page .layout .main .content_warp .content .company div .organization_chart_mobile {
    display: inline-block;
  }
  .page .layout .main .content_warp .content .company p {
    text-align: center;
    font-size: 14px;
  }
  .page .layout .main .content_warp .content .games {
    display: none;
  }
  .page .layout .main .content_warp .content .game-mobile {
    display: block;
  }
  .page .layout .main .content_warp .content .new_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 8px 20px;
  }
  .page .layout .main .content_warp .content .new_list li a {
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    padding: 12px 0 8px 0;
  }
  .page .layout .main .content_warp .content .new_list li a .new_title {
    font-weight: 400;
  }
  .page .layout .main .content_warp .content .new_list li:first-child a {
    padding-top: 0;
  }
  .page .layout .main .content_warp .content .new_detail {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .page .layout .main .content_warp .content .new_detail .bread_crumb {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .page .layout .main .content_warp .content .new_detail .detail_content .time {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .page .layout .main .content_warp .content .new_detail .detail_content h3 {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .page .layout .main .content_warp .content .new_detail .detail_content p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .page .layout .main .content_warp .content .notice_list {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px 8px 20px;
  }
  .page .layout .main .content_warp .content .notice_list li a {
    font-size: 14px;
    line-height: 26px;
    padding: 12px 0 8px 20px;
  }
  .page .layout .main .content_warp .content .notice_list li a .new_title {
    font-weight: 400;
  }
  .page .layout .main .content_warp .content .notice_list li a::before {
    width: 15px;
    height: 18px;
    background-size: 100%;
  }
  .page .layout .main .content_warp .content .notice_list li:first-child a {
    padding-top: 0;
  }
  .page .layout .main .content_warp .content .notice_list li:first-child a::before {
    margin-top: -6px;
  }
  .page .layout .main .content_warp .content .investor_info {
    width: 100%;
    padding: 0 20px;
  }
  .page .layout .main .content_warp .content .investor_info h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .page .layout .main .content_warp .content .investor_info h4 {
    margin-top: 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content {
    flex-direction: column;
    margin-top: 24px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div {
    width: 100%;
    height: auto;
    min-height: 80px;
    padding: 16px 10px 16px 50px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p {
    font-size: 14px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p img {
    transform: scale(0.8);
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div .location {
    height: auto;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div p:last-child {
    margin-top: 10px;
  }
  .page .layout .main .content_warp .content .investor_info .investor_info_content > div:last-child {
    margin-top: 24px;
  }
  .page .layout .footer {
    padding: 30px 0 0 0;
    height: 325px;
    overflow: hidden;
  }
  .page .layout .footer .center-content {
    width: calc(100% - 40px);
  }
  .page .layout .footer .center-content .content-img {
    text-align: center;
  }
  .page .layout .footer .center-content .content-word {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .page .layout .footer .center-content .content-word .statements {
    width: 170px;
    margin-bottom: 10px;
  }
  .page .layout .footer .center-content .content-word .statements dl dt {
    margin-bottom: 8px;
  }
  .page .layout .footer .center-content .content-word .statements dl dd .wechat-dialog {
    z-index: 99;
    bottom: auto;
    top: 100%;
    left: 0;
  }
  .page .layout .footer .center-content .content-word .info-list {
    display: none;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile {
    display: block;
  }
  .page .layout .footer .center-content .content-word .info-list-mobile p {
    text-align: center;
  }
  .page .en_US .main .intro-warp {
    height: 360px;
  }
  .secondary_page .layout .main .banner_warp {
    margin-top: 0px;
    height: 260px;
  }
  .secondary_page .layout .main .banner_warp .secondary_page_title {
    padding: 70px 0 0 0;
  }
  .secondary_page .layout .main .banner_warp .secondary_page_title .l_title {
    font-size: 30px;
    line-height: 30px;
  }
  .secondary_page .en_US .main .banner_warp {
    margin-top: 0px;
    height: 260px;
  }
  .secondary_page .en_US .main .banner_warp .secondary_page_title {
    padding: 70px 0 0 0;
  }
  .secondary_page .en_US .main .banner_warp .secondary_page_title .en_title {
    font-size: 24px;
    line-height: 30px;
  }
  .full_screen {
    overflow: hidden;
  }
  .full_screen .page .layout .main .playing {
    background-color: #000;
  }
  .full_screen .page .layout .main .playing video {
    height: auto;
  }
}

// 通用常量
@white-color: #fff; // 白色
@gray-color-1: #939393; // 灰色
@gray-color-2: #8e8e8e;
@gray-color-3: #dddddd;
@gray-color-4: #5e5e5e;
@gray-color-5: #f0f0f0;
@black-color-1: #000; // 黑色
@black-color-2: #262626;
@black-color-3: #293241;
@black-color-4: #333333;
@blue-color-1: #507fff; // 蓝色
@blue-color-2: #97c1d7;
@orange-color-1: #f60; // 橘色

@hover-color: @blue-color-1;

// 通用样式类
.translate(@x, @y) {
    transform: translate(@x, @y);
    -webkit-transform: translate(@x, @y);
}

.autoLineFeed {
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
}

.omit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

body {
    .page {
        height: 100vh;
        overflow: hidden;
        opacity: 0;
        transition: all 0.75s;
    }

    background-color: #fafafa;
}

// .bg-gray {
//     background-color: #ebebeb;
// }
// .bg-gray-2 {
//     background-color: #fafafa;
// }

.achieve {
    .page {
        height: auto;
        overflow: auto;
        opacity: 1;
    }
}

// 页面样式
.page {
    .layout {
        position: relative;
        overflow: hidden;

        // .autoLineFeed();
        .header {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 84px;
            z-index: 1000;
            background: @black-color-1;

            .header-content {
                position: absolute;
                left: 50%;
                top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 72%;
                .translate(-50%, 0);
                box-sizing: border-box;
                padding: 15px 0 0 0;

                .logo {
                    cursor: pointer;

                    img {
                        width: 170px;
                        height: 44px;
                        position: relative;
                        top: 5px;
                    }
                }

                .nav {
                    &>ul {
                        display: flex;
                        width: 550px;
                        justify-content: space-between;
                        align-items: center;

                        li {
                            display: inline-flex;
                            cursor: pointer;
                            color: @white-color;
                            font-size: 12px;
                            align-items: center;
                            position: relative;

                            &:hover {
                                color: @hover-color;
                            }

                            &>a {
                                cursor: pointer;
                                color: @white-color;
                                font-size: 12px;

                                &:hover {
                                    color: @hover-color;

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        bottom: -35px;
                                        left: 50%;
                                        width: 130%;
                                        .translate(-50%, 0);
                                        border-bottom: 2px solid @hover-color;
                                    }
                                }
                            }
                        }

                        .active {
                            &>a::before {
                                content: "";
                                position: absolute;
                                bottom: -35px;
                                left: 50%;
                                width: 130%;
                                .translate(-50%, 0);
                                border-bottom: 2px solid @hover-color;
                            }
                        }

                        .product {
                            &>a>b {
                                position: relative;
                                top: 2px;
                                left: 5px;
                                display: inline-block;
                                border: 4px solid transparent;
                                border-top: 4px solid @white-color;
                            }

                            &>a:hover {
                                b {
                                    border-top: 4px solid @hover-color;
                                }
                            }

                            .game_list {
                                display: none;
                                position: absolute;
                                top: 51px;
                                left: 50%;
                                width: 170px;
                                background: rgba(0, 0, 0, .8);
                                .translate(-50%, 0);

                                dd {
                                    display: block;
                                    text-align: center;

                                    a {
                                        color: @white-color;
                                        display: block;
                                        line-height: 36px;
                                        height: 36px;

                                        &:hover {
                                            color: @hover-color;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .game_list {
                                    display: block;
                                }

                                &::after {
                                    display: block;
                                    position: absolute;
                                    content: "";
                                    width: 110px;
                                    height: 40px;
                                    top: 16px;
                                    left: 50%;
                                    .translate(-50%, 0);
                                }
                            }
                        }
                    }
                }

                .language {
                    position: absolute;
                    right: -6%;
                    .translate(100%, 0);

                    ul {
                        display: flex;

                        li {
                            padding: 0 4px;
                            font-size: 12px;
                            color: @gray-color-2;

                            a {
                                font-size: 12px;
                                color: @gray-color-2;

                                &:hover {
                                    color: @white-color;
                                }
                            }
                        }

                        .selected {
                            a {
                                color: @white-color;
                            }

                        }
                    }
                }
            }
        }

        .header_2 {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 70px;
            z-index: 10;
            background: @black-color-1;
            display: none;

            .header_2_content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;

                .header_2_nav {
                    position: absolute;
                    top: -400px;
                    z-index: 0;
                    width: 100%;
                    left: 0;
                    background: rgba(18, 29, 31, .9);
                    padding-bottom: 10px;
                    transition: all .5s ease;

                    li {
                        text-align: center;
                        height: 60px;
                        line-height: 60px;

                        a {
                            color: @white-color;
                            font-size: 16px;
                            display: block;

                            &:hover {
                                color: @hover-color;
                            }
                        }
                    }
                }

                &>a {
                    position: relative;
                    z-index: 10;

                    .logo {
                        margin-left: 20px;

                        img {
                            width: 136px;
                        }
                    }
                }

                .language {
                    position: relative;
                    z-index: 10;

                    ul {
                        display: inline-flex;
                        align-items: center;

                        li {
                            display: inline-block;
                            padding: 0 4px;
                            font-size: 12px;
                            color: @gray-color-2;

                            a {
                                font-size: 12px;
                                color: @gray-color-2;
                            }
                        }

                        .selected {
                            a {
                                color: @white-color;
                            }
                        }
                    }
                }

                .header_2_nav_box {
                    position: relative;
                    z-index: 10;
                    width: 64px;
                    cursor: pointer;
                    transition: all 1s;

                    .header_2_nav_more {
                        padding: 20px 20px;
                        cursor: pointer;

                        .icon-bar {
                            display: block;
                            margin: 6px 0;
                            width: 100%;
                            height: 2px;
                            background: #fff;
                            transition: all .5s ease;
                        }

                    }
                }
            }

            .open {
                .header_2_nav {
                    top: 100%;
                }

                .header_2_nav_box {
                    .header_2_nav_more {
                        background: rgba(18, 29, 31, .9);

                        .top {
                            transition: all .5s ease;
                            transform: translateY(10px) rotateZ(45deg);
                            -webkit-transform: translateY(10px) rotateZ(45deg);
                        }

                        .middle {
                            width: 0;
                            transition: all .5s ease;
                        }

                        .bottom {
                            transform: translateY(-6px) rotateZ(-45deg);
                            -webkit-transform: translateY(-6px) rotateZ(-45deg);
                            transition: all .5s ease;
                        }
                    }
                }
            }
        }

        .main {
            .banner_warp {
                position: relative;
                width: 100%;
                margin-top: 84px;
                // height: calc(100vh - 84px);
                height: calc(100vh - 84px - 220px);
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .banner_img {
                    width: 1920px;
                    height: 1080px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    .translate(-50%, -50%);
                    z-index: 3;
                }

                .player_btn {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    .translate(-50%, -50%);
                    z-index: 5;
                    cursor: pointer;
                }

                &::after {
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(25, 29, 35, .55);
                    z-index: 4;
                    cursor: pointer;
                }
            }

            .playing {
                position: relative;

                .banner_img {
                    display: none;
                }

                .player_btn {
                    display: none;
                }

                &::after {
                    display: none;
                }

                .video-mask {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 998;
                    cursor: pointer;
                    background: rgba(25, 29, 35, .55);
                }

                .close-full-video {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    display: none;
                    background-color: #585757;
                    font-size: 20px;
                    text-align: center;
                    line-height: 40px;
                    color: #fff;
                    opacity: .3;
                    z-index: 999;
                }
            }

            .intro-warp {
                width: 100%;
                height: auto;
                background: url("/images/intro-bg.png") center center no-repeat;
                background-size: cover;
                margin: 90px 0;
                overflow: hidden;

                &>div {
                    width: 96%;
                    margin: 0 auto;

                    div {
                        text-align: center;
                        padding-top: 80px;
                    }

                    h3 {
                        margin-top: 30px;
                        font-size: 36px;
                        color: @white-color;
                        text-align: center;
                    }

                    h4 {
                        font-size: 18px;
                        color: @white-color;
                        text-align: center;
                    }

                    p {
                        margin-top: 25px;
                        font-size: 20px;
                        color: @white-color;
                        text-align: center;
                    }

                    .slash {
                        display: none;
                    }

                    .game_icons {
                        max-height: 300px;
                        overflow: hidden;
                        padding-top: 50px;

                        img {
                            width: 100%;
                        }
                    }
                }


            }

            .content_warp {
                .sub-nav {
                    width: 100%;
                    height: 70px;

                    ul {
                        width: 72%;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        position: relative;
                        right: -15px;
                        border-bottom: 2px solid @gray-color-3;

                        li {
                            color: @gray-color-1;
                            padding: 0 15px;
                            height: 100%;
                            display: inline-flex;
                            align-items: center;
                            position: relative;

                            a {
                                display: inline-block;
                                height: 100%;
                                line-height: 70px;
                                font-size: 14px;
                                font-weight: 500;
                                color: @gray-color-1;

                                &:hover {
                                    color: @blue-color-1;

                                    &::after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        bottom: -2px;
                                        left: 0;
                                        width: 100%;
                                        border-bottom: 2px solid @blue-color-1;
                                    }
                                }
                            }

                        }

                        .active {
                            a {
                                color: @blue-color-1;

                                &::after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    bottom: -2px;
                                    left: 0;
                                    width: 100%;
                                    border-bottom: 2px solid @blue-color-1;
                                }
                            }
                        }
                    }
                }

                .content {
                    padding-bottom: 70px;

                    .new_list {
                        width: 72%;
                        max-width: 1050px;
                        margin: 0 auto;
                        padding-bottom: 25px;

                        li {
                            a {
                                font-size: 16px;
                                display: flex;
                                justify-content: space-between;
                                padding: 25px 0;
                                border-bottom: 1px solid @gray-color-5;
                                overflow: hidden;

                                .new_title {
                                    flex: 1;
                                    color: @black-color-4;
                                    font-weight: 400;
                                    .omit();
                                }

                                .new_time {
                                    margin-left: 30px;
                                    color: @gray-color-4;
                                }

                                &:hover {
                                    .new_title {
                                        color: @hover-color;
                                    }
                                }
                            }
                        }
                    }

                    .investor_info {
                        width: 980px;
                        margin: 0 auto;
                        box-sizing: border-box;
                        padding-bottom: 20px;

                        h3 {
                            font-size: 36px;
                            line-height: 36px;
                            color: @black-color-3;
                            text-align: center;
                        }

                        h4 {
                            font-size: 18px;
                            line-height: 18px;
                            color: @black-color-3;
                            margin-top: 10px;
                            text-align: center;
                        }

                        .investor_info_content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 54px;

                            &>div {
                                width: 480px;
                                height: 120px;
                                background-color: @white-color;
                                box-shadow: 4px 0 6px rgba(4, 0, 0, 0.05);
                                border-radius: 5px;
                                box-sizing: border-box;
                                padding: 26px 50px 0 70px;

                                p {
                                    position: relative;
                                    font-size: 16px;
                                    line-height: 20px;

                                    img {
                                        position: absolute;
                                        top: 0;
                                        left: -35px;
                                    }

                                    .mail_img {
                                        top: 2px;
                                        left: -40px;
                                    }

                                    .email {
                                        margin-left: 0px;
                                    }
                                }

                                .l_address {
                                    height: 40px;
                                }

                                .postCode {
                                    margin-top: 10px;

                                    img {
                                        top: 2px;
                                        left: -39px;
                                    }
                                }

                                .l_postCode {
                                    width: 65px;
                                    display: inline-block;
                                    text-align-last: justify;
                                }

                                .location {
                                    height: 40px;
                                }

                                .text {
                                    // font-size: 14px;
                                    line-height: 20px;
                                    font-weight: 500;
                                    margin-top: 10px;
                                    color: @black-color-1;
                                }
                            }
                        }
                    }

                    .notice_list {
                        width: 72%;
                        max-width: 1050px;
                        margin: 0 auto;
                        padding: 0 0 10px 0;
                        min-height: 100px;

                        li {
                            a {
                                position: relative;
                                font-size: 16px;
                                display: flex;
                                justify-content: space-between;
                                padding: 25px 0 25px 50px;
                                border-bottom: 1px solid @gray-color-5;
                                overflow: hidden;

                                .new_title {
                                    flex: 1;
                                    color: @black-color-4;
                                    // .omit();
                                }

                                .new_time {
                                    margin-left: 30px;
                                    color: @gray-color-4;
                                }

                                &:hover {
                                    .new_title {
                                        color: @hover-color;
                                    }
                                }

                                &::before {
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    .translate(0, -50%);
                                    content: "";
                                    display: block;
                                    width: 21px;
                                    height: 24px;
                                    background: url("/images/download.png") no-repeat;
                                }
                            }
                        }
                    }

                    .load-more {
                        display: none;
                        width: 72%;
                        max-width: 1050px;
                        margin: 0 auto;
                        padding: 20px 0 0;

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        a {
                            padding: 8px 12px;
                            border: 1px solid @gray-color-4;
                            border-radius: 5px;
                            color: @gray-color-4;
                        }
                    }

                    .about_us {
                        width: 860px;
                        margin: 120px auto 50px auto;

                        p {
                            margin-top: 50px;
                            font-size: 16px;
                            line-height: 36px;
                            text-align: justify;
                        }
                    }

                    .manage_team {
                        width: 72%;
                        max-width: 694px;
                        margin: 100px auto 30px auto;

                        ul {
                            li {
                                display: flex;
                                padding: 30px 0 10px 0;
                                border-bottom: 1px solid #ddd;

                                img {
                                    width: 296px;
                                }

                                &>div {
                                    margin: 0 0 0 0px;

                                    h4 {
                                        display: flex;
                                        align-items: center;
                                        line-height: 20px;
                                        margin-bottom: 28px;

                                        b {
                                            font-size: 18px;
                                        }

                                        .separated {
                                            margin: 0 10px 0 15px;
                                        }
                                    }

                                    p {
                                        font-size: 16px;
                                        line-height: 32px;
                                        color: @black-color-1;
                                        text-align: justify;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .company {
                        width: 72%;
                        max-width: 1100px;
                        margin: 120px auto 60px auto;

                        p {
                            font-size: 16px;
                            line-height: 36px;
                        }

                        div {
                            margin: 10px auto 0 auto;
                            width: 100%;
                            text-align: center;

                            img {
                                width: 100%;
                                max-width: 1029px;
                            }
                        }

                        .organization_chart_mobile {
                            display: none;
                        }
                    }

                    .games {
                        width: 100%;

                        ul {
                            li {
                                font-size: 0;
                                position: relative;
                                overflow: hidden;
                                height: 204px;

                                .bg {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    .translate(-50%, -50%);
                                    transition: all ease-in-out 0.4s;
                                }

                                .bg-copy {
                                    position: absolute;
                                    width: 100%;
                                    left: 0;
                                    top: 0;
                                    z-index: 5;
                                }

                                &::after {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    content: "";
                                    z-index: 8;
                                    background-color: rgba(25, 29, 35, .55);
                                    ;
                                }

                                &:hover {
                                    .bg {
                                        transform: translate(-50%, -50%) scale(1.1);
                                    }
                                }

                                a {
                                    width: 850px;
                                    margin: 0 auto;
                                    position: absolute;
                                    z-index: 10;
                                    top: 0;
                                    left: 50%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .translate(-50%, 0);

                                    &>div {
                                        width: 600px;
                                    }

                                    h3 {
                                        font-size: 16px;
                                        color: @white-color;
                                        margin-bottom: 10px;
                                    }

                                    p {
                                        font-size: 12px;
                                        line-height: 19px;
                                        color: @white-color;
                                        text-align: justify;
                                        margin-bottom: 5px;
                                    }
                                }

                                .game-5-logo {
                                    position: relative;
                                    margin-left: -26px;
                                }

                                .game-6-logo {
                                    position: relative;
                                    left: -10px;
                                    top: -2px;
                                }

                                .game-11-logo {
                                    position: relative;
                                    margin-left: 16px;
                                }

                                .game-10-logo {
                                    position: relative;
                                    margin-left: 16px;
                                }
                            }

                            // .game-bg-1 {
                            //     background: url("/images/game-bg-1.jpg") center center no-repeat;
                            //     background-size: cover;
                            // }
                            // .game-bg-2 {
                            //     background: url("/images/game-bg-2.jpg") center center no-repeat;
                            //     background-size: cover;
                            // }
                            // .game-bg-3 {
                            //     background: url("/images/game-bg-3.jpg") center center no-repeat;
                            //     background-size: cover;
                            // }
                            // .game-bg-4 {
                            //     background: url("/images/game-bg-4.jpg") center center no-repeat;
                            //     background-size: cover;
                            // }
                            // .game-bg-5 {
                            //     background: url("/images/game-bg-5.jpg") center center no-repeat;
                            //     background-size: cover;
                            // }
                        }
                    }

                    .game-mobile {
                        display: none;
                        width: 100%;
                        padding: 0 20px;
                        box-sizing: border-box;

                        ul {
                            li {
                                border-radius: 5px 5px 0 0;
                                overflow: hidden;
                                margin-bottom: 30px;

                                &:last-child {
                                    margin-bottom: 0px;
                                }

                                a {
                                    .img-box {
                                        position: relative;
                                        font-size: 0;

                                        img {
                                            width: 100%;
                                        }

                                        .mask {
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            top: 0;
                                            left: 0;
                                            background-color: rgba(18, 29, 31, .3);
                                            transition: all .25s;
                                        }
                                    }

                                    .info {
                                        padding: 10px 16px 14px 16px;
                                        background-color: #fff;
                                        display: flex;
                                        justify-content: space-between;

                                        &>div:first-child {
                                            flex: 1;
                                        }

                                        h3 {
                                            color: #090D12;
                                            font-weight: bold;
                                            font-size: 20px;
                                        }

                                        p {
                                            font-size: 12px;
                                            color: #090D12;
                                            margin-top: 8px;
                                            line-height: 20px;
                                            text-align: justify;
                                        }

                                        .more {
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 50%;
                                            border: 1px solid #d2d2d2;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-left: 2px;

                                            span {
                                                width: 8px;
                                                height: 8px;
                                                margin-left: -4px;
                                                border: 1px solid #d2d2d2;
                                                display: block;
                                                border-left: 0;
                                                border-bottom: 0;
                                                transform: rotate(45deg);
                                                -webkit-transform: rotate(45deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new_detail {
                        width: 72%;
                        max-width: 1050px;
                        margin: 0 auto;
                        padding: 0 0 0px 0;

                        .bread_crumb {
                            line-height: 24px;
                            margin-bottom: 25px;

                            a {
                                color: @black-color-1;

                                &:hover {
                                    color: @hover-color;
                                }
                            }

                            span {
                                color: @hover-color;
                            }
                        }

                        .detail_content {
                            .time {
                                color: @hover-color;
                                margin-bottom: 20px;
                            }

                            h3 {
                                font-size: 24px;
                                margin-bottom: 20px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 24px;
                                margin-bottom: 14px;
                            }

                            img {
                                display: block;
                                margin: 0 auto;
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            width: 100%;
            height: 234px;
            box-sizing: border-box;
            padding: 38px 0 0 0;
            background-color: #090d12;

            .center-content {
                width: 72%;
                margin: 0 auto;

                .content-img {
                    text-align: right;
                    display: none;
                }

                .content-word {
                    display: flex;
                    margin-top: 24px;
                    justify-content: space-between;

                    .statements {
                        display: flex;
                        width: 200px;
                        justify-content: space-between;

                        dl {
                            dt {
                                font-size: 12px;
                                line-height: 20px;
                                font-weight: 600;
                                color: @white-color;
                                margin-bottom: 16px;
                            }

                            dd {
                                font-size: 12px;
                                color: rgba(255, 255, 255, .8);
                                line-height: 26px;
                                cursor: pointer;
                                position: relative;

                                a {
                                    color: rgba(255, 255, 255, .8);
                                    font-size: 12px;

                                    &:hover {
                                        color: @hover-color;
                                    }
                                }

                                &:hover {
                                    color: @hover-color;
                                }

                                .wechat-dialog {
                                    display: none;
                                    width: 110px;
                                    text-align: center;
                                    position: absolute;
                                    bottom: -40px;
                                    left: 35px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    background-color: @white-color;
                                    color: @black-color-1;
                                    border-radius: 5px;
                                }
                            }

                            .pc-wechat:hover {
                                .wechat-dialog {
                                    display: block;
                                }
                            }
                        }
                    }

                    .info-list {
                        position: relative;
                        right: -10px;
                        top: -2px;

                        p {
                            font-size: 12px;
                            color: @white-color;
                            line-height: 25px;
                            font-weight: 400;
                            .omit();

                            a {
                                color: @white-color;

                                &:hover {
                                    color: @hover-color;
                                }
                            }
                        }
                    }

                    .info-list-mobile {
                        display: none;

                        p {
                            font-size: 12px;
                            color: @white-color;
                            line-height: 25px;

                            a {
                                color: @white-color;

                                &:hover {
                                    color: @hover-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // 英语版样式兼容
    .en_US {
        .header {
            .header-content {
                .nav>ul {
                    width: 700px;

                    .active {
                        a::before {
                            width: 100%;
                        }
                    }

                    li {
                        a:hover {
                            &:before {
                                width: 100%;
                            }
                        }
                    }

                    .product {
                        .game_list {
                            width: 190px;
                        }
                    }
                }
            }
        }

        .main {
            .content_warp {
                .content {
                    .investor_info {}

                    .new_list {
                        li {
                            a {
                                height: auto !important;

                                .new_title {
                                    white-space: initial;
                                    line-height: 32px;
                                }

                                .new_time {
                                    line-height: 32px;
                                }
                            }
                        }
                    }

                    .games {
                        ul {
                            li {
                                a {
                                    width: 1100px;

                                    h3 {
                                        margin-bottom: 5px;
                                    }

                                    div {
                                        width: 850px;
                                    }
                                }
                            }

                            .game-bg-1 {
                                a {
                                    div {
                                        p {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .company {
                        div {
                            width: 100%;

                            img {
                                max-width: 1095px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.secondary_page {
    .layout {
        .header {
            background: rgba(0, 0, 0, 0.8);
        }

        .header_2 {
            background: rgba(0, 0, 0, 0.8);
        }

        .main {
            .banner_warp {
                width: 100%;
                margin-top: 0px;
                height: 480px;
                display: block;

                .banner_img {
                    width: 1920px;
                    height: 480px;
                    position: absolute;
                    z-index: 0;
                }

                .secondary_page_title {
                    box-sizing: border-box;
                    height: 100%;
                    padding: 84px 0 0 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    z-index: 3;
                    position: relative;

                    .l_title {
                        font-size: 42px;
                        font-weight: bold;
                        color: @white-color;
                    }

                    .en_title {
                        font-size: 42px;
                        color: @white-color;
                        font-weight: 500;
                        display: none;
                    }

                    // h4 {
                    //     font-size: 20px;
                    //     color: @white-color;
                    //     font-weight: inherit;
                    //     margin-top: 0px;
                    //     font-weight: 100;
                    // }
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .en_US {
        .main {
            .banner_warp {
                .secondary_page_title {
                    .l_title {
                        display: none;
                    }

                    .en_title {
                        display: block;
                    }
                }
            }
        }
    }
}

//视频全屏样式
.full_screen {
    overflow: hidden;

    .page .layout {
        .main .playing {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 999;
            margin-top: 0;

            .video-mask {
                cursor: context-menu;
            }

            .close-full-video {
                display: block;
            }
        }

        .header {
            z-index: 10;
        }
    }
}




@import './adapter.less';